module.exports = [{
      plugin: require('C:/code/bitbucket/joningi/breidablikfaq/node_modules/gatsby-remark-images/gatsby-browser'),
      options: {"plugins":[],"maxWidth":390,"showCaptions":true},
    },{
      plugin: require('C:/code/bitbucket/joningi/breidablikfaq/node_modules/gatsby-plugin-catch-links/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/code/bitbucket/joningi/breidablikfaq/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-132815827-1"},
    },{
      plugin: require('C:/code/bitbucket/joningi/breidablikfaq/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/code/bitbucket/joningi/breidablikfaq/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
